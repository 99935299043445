import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import  Home  from "../Home/Home";
// import About from "../About/About";
// import Contact from "../Contact/Contact";
// import BlogListing from "../Blogs/BlogListing";
// import BlogDetails from "../BlogDetails/BlogDetails";
// import Layout from "../Layouts/Layout";
import ScrollToTop from "./ScrollToTop";
// import Login from "../login/Login";
// import AdminLayout from "../Layouts/AdminLayout";
// import Dashboard from "../adminPanel/dashboard/Dashboard";
// import BlogListingAdmin from "../adminPanel/blogs/BlogListingAdmin";
// import AuthorListing from "../adminPanel/author/AuthorListing";
// import Tags from "../adminPanel/tags/Tags";
// import  TaxiSoftware  from "../TaxiSoftware/TaxiSoftware";
// import Category from "../adminPanel/category/Category";
// import AddCategory from "../adminPanel/category/AddCategory";
// import EditCategory from "../adminPanel/category/EditCategory";
// import AddTags from "../adminPanel/tags/AddTags";
// import EditTags from "../adminPanel/tags/EditTags";
// import AddAuthor from "../adminPanel/author/AddAuthor";
// import EditAuthor from "../adminPanel/author/EditAuthor";
// import AddBlogAdmin from "../adminPanel/blogs/AddBlogAdmin";
// import EditBlogAdmin from "../adminPanel/blogs/EditBlogAdmin";
// import ContactListingAdmin from "../adminPanel/contact/contactListingAdmin";
// import SeoListing from "../adminPanel/seo/SeoListing";
// import SeoCreate from "../adminPanel/seo/SeoCreate";
// import SeoEdit from "../adminPanel/seo/SeoEdit";
// import ShuttleSoftware from "../shuttle_software/ShuttleSoftware";
// import CarRental from "../carRental/CarRental";
// import PageNotFound from "../404page/PageNotFound";
// import ThankYou from "../thankyou/ThankYou";
// import CarPooling from "../carPooling/CarPooling";
// import PrivacyPolicy from "../privacy/PrivacyPolicy";
// import CaseStudy from "../caseStudy/CaseStudy";
// import CaseStudyDetail from "../caseStudyDetails/CaseStudyDetail";
// import Pricing from "../pricing/Pricing";
// import WhiteLabelSolutions from "../WhiteLabelSolutions/WhiteLabel";
// import Features from "../Features/Features";
// import BikeRental from "../bikeRental/BikeRental";
// import TaxiDispatchSoftware from "../taxiDispatchSoftware/taxiDispatchSoftware";
// import FeaturePageListing from "../adminPanel/webPages/FeaturePageListing";
// import FeaturePageCreate from "../adminPanel/webPages/FeaturePageCreate";
// import FeaturePageEdit from "../adminPanel/webPages/FeaturePageEdit";
// import FeatureEdit from "../adminPanel/feature/FeatureEdit";
// import FeatureCreate from "../adminPanel/feature/FeatureCreate";
// import FeatureListing from "../adminPanel/feature/FeatureListing";
// import FeatureTable from "../Features/FeatureTable";
// import CustomAppDevelopment from "../customAppDevelopment/CustomAppDevelopment";
// import WebAppDevelopment from "../webAppDevelopment/WebAppDevelopment";
// import QueryPopup from "../Query_popup/QueryPopup";
// import ReloadRoute from "./ReloadRoute";
// import CaseStudyListing from "../caseStudy/CaseStudyListing";
// import CaseStudyDetailNew from "../caseStudyDetails/CaseStudyDetailNew";
// import Industries from "../industries/Industries";
// import CaseStudyListAdmin from "../adminPanel/caseStudy/CaseStudyListAdmin";
// import AddCaseStudy from "../adminPanel/caseStudy/AddCaseStudy";
// import EditCaseStudy from "../adminPanel/caseStudy/EditCaseStudy";
// import CaseCategoryList from "../adminPanel/caseCategory/CaseCategoryList";
// import AddCaseCategory from "../adminPanel/caseCategory/AddCaseCategory";
// import EditCaseCategory from "../adminPanel/caseCategory/EditCaseCategory";

// import Industries from "../industries/Industries";
// import HealthCare from "../healthcare/HealthCare";

const FoodAndGrocery = lazy(() => import("../foodAndGrocery/FoodAndGrocery"));
const HealthCare = lazy(() => import("../healthcare/HealthCare"));
const Industries = lazy(() => import("../industries/Industries"));
const Home = lazy(() => import("../Home/Home"));
const About = lazy(() => import("../About/About"));
const Contact = lazy(() => import("../Contact/Contact"));
const BlogListing = lazy(() => import("../Blogs/BlogListing"));
const BlogDetails = lazy(() => import("../BlogDetails/BlogDetails"));
const Layout = lazy(() => import("../Layouts/Layout"));
const Login = lazy(() => import("../login/Login"));
const AdminLayout = lazy(() => import("../Layouts/AdminLayout"));
const Dashboard = lazy(() => import("../adminPanel/dashboard/Dashboard"));
const BlogListingAdmin = lazy(() =>
  import("../adminPanel/blogs/BlogListingAdmin")
);
const AddBlogAdmin = lazy(() => import("../adminPanel/blogs/AddBlogAdmin"));
const EditBlogAdmin = lazy(() => import("../adminPanel/blogs/EditBlogAdmin"));
const AuthorListing = lazy(() => import("../adminPanel/author/AuthorListing"));
const AddAuthor = lazy(() => import("../adminPanel/author/AddAuthor"));
const EditAuthor = lazy(() => import("../adminPanel/author/EditAuthor"));
const Tags = lazy(() => import("../adminPanel/tags/Tags"));
const AddTags = lazy(() => import("../adminPanel/tags/AddTags"));
const EditTags = lazy(() => import("../adminPanel/tags/EditTags"));
const Category = lazy(() => import("../adminPanel/category/Category"));
const AddCategory = lazy(() => import("../adminPanel/category/AddCategory"));
const EditCategory = lazy(() => import("../adminPanel/category/EditCategory"));
const SeoListing = lazy(() => import("../adminPanel/seo/SeoListing"));
const SeoCreate = lazy(() => import("../adminPanel/seo/SeoCreate"));
const SeoEdit = lazy(() => import("../adminPanel/seo/SeoEdit"));
const ShuttleSoftware = lazy(() =>
  import("../shuttle_software/ShuttleSoftware")
);
const CarRental = lazy(() => import("../carRental/CarRental"));
const PageNotFound = lazy(() => import("../404page/PageNotFound"));
const ThankYou = lazy(() => import("../thankyou/ThankYou"));
const CarPooling = lazy(() => import("../carPooling/CarPooling"));
const PrivacyPolicy = lazy(() => import("../privacy/PrivacyPolicy"));
const CaseStudyListing = lazy(() => import("../caseStudy/CaseStudyListing"));

const CaseStudyDetailNew = lazy(() =>
  import("../caseStudyDetails/CaseStudyDetailNew")
);
const CaseStudyListAdmin = lazy(() =>
  import("../adminPanel/caseStudy/CaseStudyListAdmin")
);
const AddCaseStudy = lazy(() => import("../adminPanel/caseStudy/AddCaseStudy"));
const EditCaseStudy = lazy(() =>
  import("../adminPanel/caseStudy/EditCaseStudy")
);
const CaseCategoryList = lazy(() =>
  import("../adminPanel/caseCategory/CaseCategoryList")
);
const AddCaseCategory = lazy(() =>
  import("../adminPanel/caseCategory/AddCaseCategory")
);
const EditCaseCategory = lazy(() =>
  import("../adminPanel/caseCategory/EditCaseCategory")
);

const Pricing = lazy(() => import("../pricing/Pricing"));
const WhiteLabelSolutions = lazy(() =>
  import("../WhiteLabelSolutions/WhiteLabel")
);
const Features = lazy(() => import("../Features/Features"));
const BikeRental = lazy(() => import("../bikeRental/BikeRental"));
const TaxiDispatchSoftware = lazy(() =>
  import("../taxiDispatchSoftware/taxiDispatchSoftware")
);
const CustomAppDevelopment = lazy(() =>
  import("../customAppDevelopment/CustomAppDevelopment")
);
const WebAppDevelopment = lazy(() =>
  import("../webAppDevelopment/WebAppDevelopment")
);
// const Industries = lazy(() => import("../industries/Industries"));

const TaxiSoftware = lazy(() => import("../TaxiSoftware/TaxiSoftware"));
const ContactListingAdmin = lazy(() =>
  import("../adminPanel/contact/contactListingAdmin")
);
const FeaturePageListing = lazy(() =>
  import("../adminPanel/webPages/FeaturePageListing")
);
const FeaturePageCreate = lazy(() =>
  import("../adminPanel/webPages/FeaturePageCreate")
);
const FeaturePageEdit = lazy(() =>
  import("../adminPanel/webPages/FeaturePageEdit")
);
const FeatureEdit = lazy(() => import("../adminPanel/feature/FeatureEdit"));
const FeatureCreate = lazy(() => import("../adminPanel/feature/FeatureCreate"));
const FeatureListing = lazy(() =>
  import("../adminPanel/feature/FeatureListing")
);
const FeatureTable = lazy(() => import("../Features/FeatureTable"));
const ReloadRoute = lazy(() => import("./ReloadRoute"));
const CaseStudy = lazy(() => import("../caseStudy/CaseStudy"));
const CaseStudyDetail = lazy(() =>
  import("../caseStudyDetails/CaseStudyDetail")
);

const LyftClone = lazy(() => import("../LYFT_Clone/LyftClone"));
const MediaPage = lazy(() => import("../Media_Page/MediaPage"));

export const NavRouter = () => {
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="Login" element={<Login />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />

            <Route path="blog" element={<BlogListingAdmin />} />
            <Route path="add-blog" element={<AddBlogAdmin />} />
            <Route path="edit-blog" element={<EditBlogAdmin />} />
            <Route path="edit-blog/:blog_id" element={<EditBlogAdmin />} />

            <Route path="author" element={<AuthorListing />} />
            <Route path="add-author" element={<AddAuthor />} />
            <Route path="edit-author" element={<EditAuthor />} />
            <Route path="edit-author/:author_id" element={<EditAuthor />} />

            <Route path="tags" element={<Tags />} />
            <Route path="add-tags" element={<AddTags />} />
            <Route path="edit-tags" element={<EditTags />} />
            <Route path="edit-tags/:tags_id" element={<EditTags />} />

            <Route path="category" element={<Category />} />
            <Route path="add-category" element={<AddCategory />} />
            <Route path="edit-category" element={<EditCategory />} />
            <Route
              path="edit-category/:category_id"
              element={<EditCategory />}
            />

            <Route path="seo" element={<SeoListing />} />
            <Route path="add-seo" element={<SeoCreate />} />
            <Route path="edit-seo" element={<SeoEdit />} />
            <Route path="edit-seo/:seo_id" element={<SeoEdit />} />

            <Route path="feature-pages" element={<FeaturePageListing />} />
            <Route path="add-feature-page" element={<FeaturePageCreate />} />
            <Route path="edit-feature-page" element={<FeaturePageEdit />} />
            <Route
              path="edit-feature-page/:featurepage_id"
              element={<FeaturePageEdit />}
            />

            <Route path="features" element={<FeatureListing />} />
            <Route path="add-feature" element={<FeatureCreate />} />
            <Route path="edit-feature" element={<FeatureEdit />} />
            <Route
              path="edit-feature/:featurepage_id"
              element={<FeatureEdit />}
            />

            <Route path="case-study" element={<CaseStudyListAdmin />} />
            <Route path="add-case-study" element={<AddCaseStudy />} />
            <Route path="edit-case-study" element={<EditCaseStudy />} />
            <Route
              path="edit-case-study/:casestudy_id"
              element={<EditCaseStudy />}
            />

            <Route path="case-category" element={<CaseCategoryList />} />
            <Route path="add-case-category" element={<AddCaseCategory />} />
            <Route path="edit-case-category" element={<EditCaseCategory />} />
            <Route
              path="edit-case-category/:casecategory_id"
              element={<EditCaseCategory />}
            />

            <Route path="contact" element={<ContactListingAdmin />} />
          </Route>

          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="blog" element={<BlogListing />} />

            <Route path="blog/:blog_slug" element={<BlogDetails />} />
            <Route path="features/:featurepageId" element={<Features />} />
            {/* <Route path="/features/:featurepageId" component={FeatureTable} /> */}

            <Route
              path="best-taxi-dispatch-software"
              element={<TaxiSoftware />}
            />
            <Route path="shuttle-software" element={<ShuttleSoftware />} />
            <Route path="car-rental-software" element={<CarRental />} />
            <Route path="thankyou" element={<ThankYou />} />
            <Route path="404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to={"/404"} />} />
            <Route
              path="white-lable-carpool-app-dispatch-software"
              element={<CarPooling />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />

            {/* <Route path="case-study" element={<CaseStudy />} /> */}
            <Route path="case-study" element={<CaseStudyListing />} />

            {/* <Route path="case-study-detail" element={<CaseStudyDetail />} /> */}
            <Route
              path="case-study/:casestudy_slug"
              element={<CaseStudyDetailNew />}
            />

            <Route path="pricing" element={<Pricing />} />
            <Route
              path="white-label-solutions"
              element={<WhiteLabelSolutions />}
            />

            <Route
              path="/car-pooling"
              element={
                <Navigate
                  to="/white-lable-carpool-app-dispatch-software"
                  replace
                />
              }
            />
            <Route
              path="/taxi-software"
              element={<Navigate to="/best-taxi-dispatch-software" replace />}
            />
            <Route
              path="/car-rental"
              element={<Navigate to="/car-rental-software" replace />}
            />
            <Route path="features" element={<Features />} />
            <Route path="bike-rental" element={<BikeRental />} />
            <Route
              path="airport-transfer-software"
              element={<TaxiDispatchSoftware />}
            />

            <Route
              path="custom-app-development"
              element={<CustomAppDevelopment />}
            />

            <Route path="web-app-development" element={<WebAppDevelopment />} />
            <Route path="industries" element={<Industries />} />
            <Route path="healthcare" element={<HealthCare />} />
            <Route path="food-and-grocery-app" element={<FoodAndGrocery />} />
            <Route path="lyft-clone-app" element={<LyftClone />} />
            <Route path="media" element={<MediaPage />} />

            {/* <Route path="QueryPopup" element={<QueryPopup />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};
